import {
	get
} from './axios.js'

let api = '/api'
if (process.env.NODE_ENV === 'development') {
	api = '/apis/api'
}
let systemApi = api + '/bosh-system'

// 获取网站信息
export const getSite = () => get(systemApi + '/web/base')
// 获取页面信息
export const getPage = query => get(systemApi + '/web/pageById' + query)
// 获取列表信息
export const getList = query => get(systemApi + '/web/page/sonList' + query)