<template>
	<div id="app">
		<component :is="headerComponent" :site="site"></component>
		<router-view v-if="site.moduleType" :site="site" />
		<component :is="footerComponent" :site="site"></component>
	</div>
</template>
<script>
	import { getSite } from '@/axios/api.js'

	export default {
		name: 'App',
		data() {
			return {
				headerComponent: null,
				footerComponent: null,
				site: {},
				siteTheme: [1],
				headerTheme: [1],
				footerTheme: [1],
				listTheme: [1, 2, 3, 4, 5, 6]
			}
		},
		mounted() {
			this.getSite()
		},
		methods: {
			getSite() {
				const loading = this.$loading({
					lock: true,
					background: 'rgba(0, 0, 0, 0.7)'
				})
				getSite().then(res => {
					if(res.code == '200') {
						this.site = res.data
						this.site.listTheme = this.listTheme
						this.site.moduleType = this.getSiteTheme(this.site.moduleType)
						this.site.pageHead = this.getHeaderTheme(this.site.pageHead)
						this.site.pageFoot = this.getFooterTheme(this.site.pageFoot)
						this.headerComponent = () =>
							import('@/components/theme' + this.site.moduleType + '/header' + this.site.pageHead + '.vue')
						this.footerComponent = () =>
							import('@/components/theme' + this.site.moduleType + '/footer' + this.site.pageFoot + '.vue')
					} else if(res.msg) {
						this.$message.error(res.msg)
					}
					loading.close()
				})
			},
			getSiteTheme(theme) {
				return this.getTheme(theme, this.siteTheme)
			},
			getHeaderTheme(theme) {
				return this.getTheme(theme, this.headerTheme)
			},
			getFooterTheme(theme) {
				return this.getTheme(theme, this.footerTheme)
			},
			getTheme(theme, themes) {
				var length = themes.length,
					inThemes = false
				for(var i = 0; i < length; i++) {
					if(!inThemes) {
						if(themes[i] == theme) {
							inThemes = true
							break
						}
					}
				}
				if(!inThemes) theme = themes[0]
				return theme
			}
		}
	}
</script>
<style lang="scss">
	a {
		color: $--color-primary;
	}
</style>