import axios from 'axios'
import router from '../router';

if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = 'https://www.bs.ink'
} else {
  axios.defaults.baseURL = ''
}
// 请求超时时间
axios.defaults.timeout = 60000;
// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 添加请求拦截器
axios.interceptors.request.use(
  config => {
//  let token = localStorage.getItem('token')
//  if (token != '') {
//    config.headers['Authorization'] = 'Bearer ' + token
//  }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  response => {
    let code = response.data.code || response.code;
    switch (code) {
      case 200:
        return Promise.resolve(response);
      case 401:
      case 1005000:
        localStorage.removeItem("token");
        localStorage.removeItem("userInfo");

        // location.href = '/login'
        router.push('/login')
        return Promise.reject({
          ...response,
          data: {
            ...response.data,
            message: '登录超时'
          }
        });
      default:
        return Promise.reject(response);
    }
  },
  error => {
    let response = error.response || '';
    switch (response.code) {
      case 401:
        localStorage.removeItem("token");
        localStorage.removeItem("userInfo");
        router.push('/login');
        router.go(0);
        return Promise.reject({
          ...response,
          data: {
            ...response.data,
            message: '登录超时'
          }
        });
      case 500:
        router.go(0);
        return Promise.reject({
          ...response,
          data: {
            ...response.data,
            message: "登录过期，请重新登录"
          }
        });
      default:
        return Promise.reject(response);
    }
  }
);

const handlePromise = (promise, resolve, reject) => {
    promise.then(res => {
        resolve(res.data);
    }).catch(err => {

      if (err && err.data && err.data.message) {
          reject(err.data)
      } else if (err && err.data && err.data.code){
          reject(err.data)
      } else {
          reject(err)
        }
    })
}

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    handlePromise(axios.get(url, params), resolve, reject);
  });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    handlePromise(axios.post(url, params), resolve, reject);
  });
}

/**
 * put方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put(url, params) {
  return new Promise((resolve, reject) => {
    handlePromise(axios.put(url, params), resolve, reject);
  });
}

/**
 * delete方法，对应post请求
 * @param {String} url [请求的url地址]
 */
export function del(url, params) {
  return new Promise((resolve, reject) => {
    handlePromise(axios.delete(url, {data: params}), resolve, reject);
  });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {File} file [上传的文件]
 * @param {Object} params [请求时携带的参数]
 */
export function postFile(url, file, params) {
  return new Promise((resolve, reject) => {
    handlePromise(axios.post(url, file, params), resolve, reject)
  });
}
