<template>
	<div class="content">
		<h1>404</h1> 页面没有找到
	</div>
</template>
<script>
	export default {
		name: 'Block404'
	}
</script>
<style lang="scss" scoped>
	.content {
		@include body_block;
		height: 800px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 50px;
		color: $--color-primary;
	}
</style>