<template>
	<div class="root" :style="'min-height: '+minHeight+'px;'">
		<Block404 v-if="show404" />

		<component v-else-if="page.id" :key="id" :is="pageComponent" :page="page" :site="site"></component>
	</div>
</template>
<script>
	import Block404 from '@/components/Block404.vue'
	import { getPage } from '@/axios/api.js'

	export default {
		name: 'Page',
		props: ['site'],
		components: {
			Block404
		},
		data() {
			return {
				id: this.$route.params.id || 'home',
				show404: false,
				pageComponent: '',
				page: {},
				minHeight: '100px'
			}
		},
		mounted() {
			this.pageComponent = () =>
				import('@/components/theme' + this.site.moduleType + '/page.vue')
			this.getPage()
		},
		watch: {
			$route(to) {
				if(to.params.id != this.id) {
					this.id = to.params.id || 'home'
					this.show404 = false
					this.getPage()
				}
			}
		},
		methods: {
			getPage() {
				const loading = this.$loading({
					lock: true,
					background: 'rgba(0, 0, 0, 0.7)'
				})
				this.page = {}
				document.title = '加载中 - ' + this.site.wbTitle
				var clientHeight = 0;
				if(document.body.clientHeight && document.documentElement.clientHeight) {
					clientHeight = (document.body.clientHeight < document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight
				} else {
					clientHeight = (document.body.clientHeight > document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight
				}
				if(clientHeight < 100) clientHeight = 100
				this.minHeight = clientHeight
				var query = ''
				if(this.id > 0) query += '?id=' + this.id
				getPage(query).then(res => {
					if(res.code == 200) {
						this.page = res.data
						document.title = this.page.pageTitle + ' - ' + this.site.wbTitle
					} else {
						this.show404 = true
					}
					this.minHeight = 100
					loading.close()
				}).catch(err => {
					this.show404 = true
					this.minHeight = 100
					loading.close()
				})
			}
		}
	}
</script>
<style lang="scss" scoped>

</style>