import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import './element-variables.scss'
import utils from './util.js';

Vue.use(ElementUI)
Vue.use(utils)

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')